import FormModel from "@/apps/core/models/formModel";
import router from "@/router";
import {
  AktivitasKeterampilan,
  AktivitasKeterampilanAPI,
} from "./aktivitasKeterampilan";
import { setRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";


export default class AktivitasKeterampilanFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new AktivitasKeterampilanAPI(),
      new AktivitasKeterampilan(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  getPayload() {
    const payload = super.getPayload();
    payload.aktivitas = router.currentRoute.params.aktivitasId;
    return payload;
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
