
































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { AktivitasKeterampilan } from "../models/aktivitasKeterampilan";
import AktivitasKeterampilanFM from "../models/aktivitasKeterampilanForm";
import { Komponen } from "@/apps/penyakit/models/komponen";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AktivitasKeterampilanForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    KomponenModalForm: () =>
      import("@/apps/penyakit/components/KomponenModalForm.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isKeterampilanModalFormActive = ref(false);
    const namaKomponen = ref("");
    const requiredFields = ["diagnosis", "keterampilan", "kompetensi"];
    const nonRequiredFields = ["hasil"];
    const form = new AktivitasKeterampilanFM(requiredFields, nonRequiredFields);
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const aktivitasKeterampilan = composition.instance as AktivitasKeterampilan;
    const aktivitasId = computed(() => {
      return (
        router.currentRoute.params.aktivitasId ??
        aktivitasKeterampilan.aktivitas?.id
      );
    });

    const setKeterampilan = (keterampilan?: Komponen) => {
      aktivitasKeterampilan.keterampilan =
        keterampilan ?? aktivitasKeterampilan.keterampilan;
      composition.validate("keterampilan");
      isKeterampilanModalFormActive.value = false;
    };
    return {
      // Data
      isKeterampilanModalFormActive,
      aktivitasKeterampilan,
      aktivitasId,
      namaKomponen,

      // Composition
      ...composition,

      // Method
      setKeterampilan,
    };
  },
});
