




































import axios from "axios";
import { defineComponent } from "@vue/composition-api";
import { ToastProgrammatic as Toast } from "buefy";
import AktivitasKeterampilanVM, {
  AktivitasKeterampilan,
} from "@/apps/aktivitas/models/aktivitasKeterampilan";
import APP_CONFIG from "@/apps/core/modules/config";
import router from "@/router";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";

export default defineComponent({
  name: "AktivitasKeterampilanDetail",
  props: {
    headerText: { type: String, required: true },
  },
  components: {
    AktivitasKomponenDetail: () =>
      import("@/apps/aktivitas/views/AktivitasKomponenDetail.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const aktKeterampilanVM = new AktivitasKeterampilanVM();
    const { viewModelRef, instance } = useViewModel(
      paramsId,
      aktKeterampilanVM
    );
    const aktKomp = instance as AktivitasKeterampilan;

    const verifikasiAktivitas = async () => {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/keterampilan/${paramsId}/`;
      const data = { verifikasi: aktKomp.verifikasi };
      try {
        await axios.patch(apiUrl, data);
        Toast.open("Data berhasil di simpan.");
        setPerawatanRefreshRequest("context-menu");
        setAktivitasRefreshRequest("context-menu");
      } catch {
        Toast.open("Data gagal di simpan.");
      }
    };

    return {
      // Data
      aktKomp,
      toUserDateTimeFormat,
      viewModelRef,

      // Method
      verifikasiAktivitas,
    };
  },
});
